const hq = jQuery.noConflict();

let ready = (callback) => {
  if (document.readyState !== "loading") callback();
  else document.addEventListener("DOMContentLoaded", callback);
}

ready(() => {
  const listItemContainer = document.querySelector("#hwx-lmdb-termine-list .hwx-lmdb-list-items");
  const filter            = hq('#hwx-lmdb-filter-location, #hwx-lmdb-filter-category');
  
  if (listItemContainer === null) return;
  
  //<editor-fold desc="List Observer">
  const observerOptions = {
    root:       null,
    rootMargin: '0px',
    threshold:  0.25
  }
  
  const observerCallback = (entries, observer) => {
    const listItem = entries[0];
    if (!listItem.isIntersecting) return;
    
    observer.unobserve(listItem.target);
    
    loadItems(listItemContainer, getFilterData(filter), getPaginationData(listItemContainer), observer, false)
  };
  
  const observer = new IntersectionObserver(observerCallback, observerOptions);
  observer.observe(listItemContainer.querySelector(".hwx-lmdb-list-item:last-child"));
  //</editor-fold>
  
  //<editor-fold desc="Filter">
  filter.on("click", "button", function () {
    let button = hq(this);
    if (button.hasClass("filter-button-all")) {
      button.addClass("active");
      button.siblings().removeClass("active");
    } else {
      button.toggleClass("active");
      button.siblings(".filter-button-all").removeClass("active");
    }
    
    let paginationData   = getPaginationData(listItemContainer);
    paginationData.items = 0;
    setPaginationDataToDom(listItemContainer, paginationData);
    
    loadItems(listItemContainer, getFilterData(filter), getPaginationData(listItemContainer), observer, true);
  });
  //</editor-fold>
});

function setPaginationDataToDom(listItemContainer, paginationData) {
  
  listItemContainer.dataset.paginationItems = paginationData.items.toString();
  listItemContainer.dataset.paginationLimit = paginationData.limit.toString();
}

function getFilterData(filter) {
  
  const activeFilters = filter.find(".active");
  const filterData    = [];
  
  activeFilters.each(function () {
    filterData.push(hq(this).data('filter'));
  });
  
  return filterData;
}

function getPaginationData(listItemContainer) {
  
  return {
    items: parseInt(listItemContainer.dataset.paginationItems),
    limit: parseInt(listItemContainer.dataset.paginationLimit),
  };
}

function loadItems(listItemContainer, filterData, paginationData, observer, clear) {
  
  hq.ajax({
            data:    {[document.getElementById("token").name]: "1", format: "json", filter: filterData, pagination: JSON.stringify(paginationData)},
            success: function (result) {
              if (clear) clearItemList();
      
              fillItemList(result.data);
      
              let listItems        = listItemContainer.querySelectorAll(".hwx-lmdb-list-item");
              paginationData.items = listItems.length;
              setPaginationDataToDom(listItemContainer, paginationData);
      
              if (result.data.length && result.data.length === paginationData.limit) observer.observe(listItemContainer.querySelector(".hwx-lmdb-list-item:last-child"));
            },
            error:   function (xhr, status, error) {
              console.error(xhr, status, error);
              console.error(xhr.responseText);
            },
          })
}

function fillItemList(data) {
  
  let template = document.querySelector('#hwx-lmdb-list-item-skeleton');
  if (template === null) {
    console.error("Template für die Veranstaltungen fehlt");
    return;
  }
  
  let itemList = document.querySelector('.hwx-lmdb-list-items');
  if (itemList === null) {
    console.error("Kontainer für die Veranstaltungen fehlt");
    return;
  }
  
  data = Object.values(data);
  for (let i = 0; i < data.length; ++i) {
    let templateClone = document.importNode(template.content, true);
    let eventData     = data[i];
    let eventStart    = new Date(eventData.event_start);
    
    let listItem = templateClone.querySelector(".hwx-lmdb-list-item");
    if (listItem === null) return;
    
    let animationDelay              = 0.25 + (0.25 * i);
    listItem.dataset.location       = eventData.location.id;
    listItem.dataset.category       = eventData.category.id;
    listItem.dataset.animationdelay = animationDelay.toString();
    listItem.style.animationDelay   = animationDelay + "s";
    
    let dateContainer = templateClone.querySelector(".hwx-lmdb-list-item-date");
    if (dateContainer !== null) {
      let dateStruct = document.createElement("div");
      dateStruct.appendChild(document.createTextNode(eventStart.getDate() + "."));
      dateStruct.appendChild(document.createElement("br"));
      dateStruct.appendChild(document.createTextNode(eventStart.toLocaleString('default', {month: 'short'})));
      dateContainer.appendChild(dateStruct);
    }
    
    let titleLink = templateClone.querySelector(".hwx-lmdb-list-item-content-title-text a");
    if (titleLink !== null) {
      titleLink.appendChild(document.createTextNode(eventData.title));
      titleLink.href  = eventData.link;
      titleLink.title = eventData.title;
    }
    
    let eventDetails = templateClone.querySelector(".hwx-lmdb-list-item-content-details");
    let eventDate    = eventDetails.querySelector(".hwx-lmdb-list-item-content-date");
    if (eventDate !== null) eventDate.appendChild(document.createTextNode(eventStart.toLocaleString('default', {weekday: 'long'})))
    
    let eventTime = eventDetails.querySelector(".hwx-lmdb-list-item-content-time");
    if (eventTime !== null) eventTime.appendChild(document.createTextNode(eventStart.toLocaleString('default', {hour: '2-digit', minute: '2-digit'})))
    
    let eventLocation = eventDetails.querySelector(".hwx-lmdb-list-item-content-location");
    if (eventLocation !== null) eventLocation.appendChild(document.createTextNode(eventData.location.title));
    
    let text = templateClone.querySelector(".hwx-lmdb-list-item-content-text");
    if (text !== null) text.appendChild(document.createTextNode(eventData.teaser));
    
    let imageLink = templateClone.querySelector(".hwx-lmdb-list-item-image a");
    if (imageLink !== null) {
      imageLink.href  = eventData.link;
      imageLink.title = eventData.title;
    }
    
    if (eventData.medien.length > 0) {
      let image = templateClone.querySelector(".hwx-lmdb-list-item-image img");
      if (image !== null) {
        image.src = eventData.medien[0].media;
        image.alt = eventData.medien[0].media_alt;
      }
    } else templateClone.querySelector(".hwx-lmdb-list-item-image").innerHTML = "";
    
    itemList.appendChild(templateClone);
  }
}

function clearItemList() {
  
  hq('.hwx-lmdb-list-items').empty();
}