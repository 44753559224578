/** @license Hyphenator_Loader 1.1.0 - client side hyphenation for webbrowsers
 *  Copyright (C) 2014  Mathias Nater, Zürich (mathias at mnn dot ch)
 *  Project and Source hosted on http://code.google.com/p/hyphenator/
 * 
 *  This JavaScript code is free software: you can redistribute
 *  it and/or modify it under the terms of the GNU Lesser
 *  General Public License (GNU LGPL) as published by the Free Software
 *  Foundation, either version 3 of the License, or (at your option)
 *  any later version.  The code is distributed WITHOUT ANY WARRANTY;
 *  without even the implied warranty of MERCHANTABILITY or FITNESS
 *  FOR A PARTICULAR PURPOSE.  See the GNU GPL for more details.
 *
 *  As additional permission under GNU GPL version 3 section 7, you
 *  may distribute non-source (e.g., minimized or compacted) forms of
 *  that code without the copy of the GNU GPL normally required by
 *  section 4, provided you include this license notice and a URL
 *  through which recipients can access the Corresponding Source.
 */
var Hyphenator_Loader=function(e){"use strict";var t,n,o,a=function(t){var n;return e.document.createElementNS?n=e.document.createElementNS("http://www.w3.org/1999/xhtml",t):e.document.createElement&&(n=e.document.createElement(t)),n},r=function(t,n){var o,r,c=e.document.getElementsByTagName("body")[0];return(o=a("div")).style.width="5em",o.style.MozHyphens="auto",o.style["-webkit-hyphens"]="auto",o.style["-ms-hyphens"]="auto",o.style.hyphens="auto",o.style.fontSize="12px",o.style.lineHeight="12px",o.style.visibility="hidden",o.lang=t,o.style["-webkit-locale"]="'"+t+"'",o.innerHTML=n,c.appendChild(o),r=o.offsetHeight,c.removeChild(o),r>12},c=function(){var c,d,i=!1,m={};for(d in t)t.hasOwnProperty(d)&&(c=r(d,t[d]),m[d]=c,i=i||!c);i&&function(t){var n,r,c;n=e.document.getElementsByTagName("head").item(0),(r=a("script")).src=o,r.type="text/javascript",n.appendChild(r),c=e.setInterval((function(){void 0!==e.Hyphenator&&(e.clearInterval(c),Hyphenator.config(t),Hyphenator.run())}),10)}(n)};return{init:function(a,r,d){t=a,o=r,n=d||{},function(e,t){var n,o,a,r={},c=e.document.addEventListener?"addEventListener":"attachEvent",d=e.document.addEventListener?"removeEventListener":"detachEvent",i=e.document.addEventListener?"":"on",m=function(n){r[(o=n||e).location.href]||a&&!o.frameElement||(a=!0,t(),r[o.location.href]=!0)},l=function(){try{e.document.documentElement.doScroll("left")}catch(t){return void e.setTimeout(l,1)}m(e)},u=function(){e.frames.length;m(e)},s=function(t){"readystatechange"===t.type&&"complete"!==e.document.readyState||(e.document[d](i+t.type,s,!1),0===e.frames.length&&m(e))};if("complete"===e.document.readyState||"interactive"===e.document.readyState)e.setTimeout(u,1);else{e.document[c](i+"DOMContentLoaded",s,!1),e.document[c](i+"readystatechange",s,!1),e[c](i+"load",u,!1),n=!1;try{n=!e.frameElement}catch(e){}e.document.documentElement.doScroll&&n&&l()}}(e,c)}}}(window);